.form-container{
    /* column-count: 2; */
    margin: 2%;
    /* display: flex; */
}
.field-container{
    margin: 4% 0% 4% 0%;
    /* margin: 1rem; */
}
.left{
    width: 48%;
    margin: 1%;
}
.right{
    width: 48%;
    margin: 1%;
}
.radio{
    color:#717392;
    font-size: 1.5vh;
    display: flex;
}
.sub-container{
    display: flex;
}
.line{
    background-color: grey;
    margin: 2vh;
    height: 0.01vh;
}
.mobile{
    display: grid;
}

/* for executive select list */

/* SelectedOption.css */
.selected-option {
    display: inline-block;
    background-color: transparent;
    border: 2px solid #007bff;
    color: #007bff;
    padding: 2px 1rem;
    font-size: 0.8rem;
    margin-bottom: 0.4rem;
    font-weight: 400;
    border-radius: 16px;
    cursor: pointer;
  }
  
  .selected-option:hover {
    background-color: #0056b3;
    color: white;
  }
  .custom-tabs .nav-link {
    min-width: 20vw; /* You can adjust the width as needed */
  }
  .custom-sub-tabs .nav-link {
    min-width: 10vw; /* You can adjust the width as needed */
    max-width: 24vw;
  }
  .t-c-container{
    background-color: #edeff3;
    position: absolute;
    top: -5vh;
    height: fit-content;
    min-width: 100vw;
    z-index: 4;
  }
  .home-nav{
    position: absolute;
    top: 1vh;
    left: 1vw;
    z-index: 1;
  }
  .edit-profile{
    position: absolute;
    top: 2vh;
    left: 60vw;
    z-index: 1;
  }

  @media only screen and (max-width: 600px) {
    .edit-profile{
      position: absolute;
      top: 2vh;
      left: 35vw;
      z-index: 1;
    }
  }

  .overlay{
    background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent black background */
    backdrop-filter: blur(10px); /* Adjust the blur strength as needed */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999; 
  }

  .background-container{
    height: 100vh;
    width: 100vw;
    background-color: #F6F6F6;
  }
  .background-image{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 31vw;
    height: 31vh;
  }
/* .print-container{
  width: 794px;
  height: fit-content;
  background-color: #9394a0;
}
.print-header{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 794px;
  height: 63px;
  font-weight: bold;
  text-decoration: underline;
}
.print-row {
  display: flex;
  margin: 10px; 
}

.print-column {
  flex: 1;
  padding: 5px;
  margin: 10px;
}

.page-break {
  page-break-before: always;
}

.print-page {
  page-break-inside: avoid;
}
.print-terms-and-condition{
  position: absolute;
  top: 1123px;
} */

