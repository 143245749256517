/* .top_rw{ background-color:#f4f4f4; }
	.td_w{ }
	button{ padding:5px 10px; font-size:14px;}
    .invoice-box {
        max-width: 890px;
        margin: auto;
        padding:10px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        font-size: 14px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        background-color: #edeff3;
        color: #555;
    }
    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
		border-bottom: solid 1px #ccc;
    }
    .invoice-box table td {
        padding: 5px;
        vertical-align:middle;
        background-color: #edeff3;
    }
    .invoice-box table tr td:nth-child(2) {
        text-align: right;
    }
    .invoice-box table tr.top table td {
        padding-bottom: 20px;
    }
    .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
    }
    .invoice-box table tr.information table td {
        padding-bottom: 40px;
    }
    .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
		font-size:12px;
    }
    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }
    .invoice-box table tr.item td{
        border-bottom: 1px solid #eee;
    }
    .invoice-box table tr.item.last td {
        border-bottom: none;
    }
    .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
    }
    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }
        .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }
    
    .rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }
    .rtl table {
        text-align: right;
    }
    .rtl table tr td:nth-child(2) {
        text-align: left;
    }

    */


    /* #centeredDiv {
        text-align: center;
    }
    table {
        border-collapse: collapse;
        width: 100%;
      }
      
      th, td {
        border: 1px solid black;
        padding: 8px;
      }
      
      th {
        background-color: #f2f2f2;
      }
    .address-container{
        display: flex;
    }
    .customer-container{
        display: flex;
        width: 100%;
    }
    .customer-left{
        text-align: justify;
        width: 50%;
    }
    .customer-right{
        text-align: justify; 
        width: 50%;
    }
    p{
        margin: 10px;
    } */

   
      /* .address-container {
        margin-top: 10px;
      } */

      /* .address-container {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
      }
      
      .address-container p {
        margin: 0; 
      }
      
       */
      /* Additional styling for other elements as needed */

      #centeredDiv {
        width: 210mm; /* A4 paper width */
        height: 297mm; /* A4 paper height */
        margin: 0 auto;
        padding: 10px;
        font-family: Arial, sans-serif;
        font-size: 14px;
      }
      
      .table-container {
        width: 100%;
        border-collapse: collapse;
        /* margin-bottom: 10px; */
      }
      
      .th-print, .td-print {
        border: 1px solid #000;
        padding: 2px 8px;
        text-align: center;
      }
      .p-print {
        margin-top: 0;
        margin-bottom: 4px;
        margin-left: 4px;
      }
      
      .customer-container {
        display: flex;
        justify-content: space-between;
      }
      
      .customer-left,
      .customer-right {
        width: 48%;
      }
      
     
      #pdf-content {
        width: 210mm; /* A4 paper width */
        height: 296.5mm; /* A4 paper height */
        font-family: Arial, sans-serif;
        font-size: 15px; /* Reduced font size */
      }
      
      .centeredDivCustomerCopy,
      .centeredDivOfficeCopy {
        width: 100%;
        margin-bottom: 1mm; /* Space between sections */
        padding: 20px;
        box-sizing: border-box;
        page-break-inside: avoid; /* Avoid breaking sections */
      }
      
      /* Other shared styles for tables, headers, etc. */
      
      .address-container {
        display: flex;
        justify-content: space-between;
      }
      
      /* Adjust specific styles for Customer Copy section */
      #centeredDivCustomerCopy {
        margin-bottom: 10mm; /* Reduce space between sections if needed */
        /* Add any specific styles */
      }
      
      /* Adjust specific styles for Office Copy section */
      #centeredDivOfficeCopy {
        /* Add any specific styles */
      }
      #signatureBlock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Arial, sans-serif;
        font-size: 15px;
      }
      
      .leftText {
        text-align: left;
        font-weight: 700;
        line-height: 0.8;
        margin: 10px;
      }
      
      .rightText {
        text-align: right;
        font-weight: 700;
        line-height: 0.8;
        margin: 10px;
      }
      
      .line-print {
        width: 45%;
        border-bottom: 1px solid black;
        margin-top: 20px;
        width: 107px;
      }

      .logo-print{
        width: 40px;
        height: 40px;
      }
      
      